<template>
  <v-container>
    <v-progress-circular v-if="loading" indeterminate color="primary" />
    <v-form v-else @submit.prevent="submit">
      <div class="mb-1 mt-6">
        <h1 class="text-h4">Basic Info</h1>
        <p>
          Update basic information about your business.
          When you're done, click <span class="text-uppercase">submit</span> button below the form.
        </p>
        <p>
          To return to restaurant management menu, click <v-chip outlined><v-icon left>mdi-arrow-left</v-icon>Back</v-chip> on your browser.
        </p>
        <v-text-field v-model="restaurantData.name" label="Restaurant Name" prepend-inner-icon="mdi-feather"/>
        <v-text-field v-model="restaurantData.address" label="Address" prepend-inner-icon="mdi-mailbox" />
        <v-text-field v-model="restaurantData.telephone" label="Phone Number" prepend-inner-icon="mdi-phone" />
        <v-text-field v-model="restaurantData.contacts.email" label="E-mail" prepend-inner-icon="mdi-email" />
        <v-text-field v-model="restaurantData.contacts.facebook" label="Facebook URL" prepend-inner-icon="mdi-facebook" />
        <v-text-field v-model="restaurantData.contacts.instagram" label="Instagram URL" prepend-inner-icon="mdi-instagram" />
        <v-text-field v-model="restaurantData.contacts.whatsApp" label="WhatsApp Number" prepend-inner-icon="mdi-whatsapp" />
        <v-text-field v-model="restaurantData.contacts.website" label="Website" prepend-inner-icon="mdi-web" />
      </div>
      <v-btn color="primary" type="submit" :loading="submitting" :disabled="submitting" large>Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'

export default {
  name: 'EditRestaurantBasicInfo',

  props: {
    restaurantId: {
      type: String,
      required: true
    }
  },

  computed: {
    collectionPath () {
      return `/restaurants/${this.restaurantId}`
    },
    user () {
      return this.$store.getters['auth/user']
    }
  },

  data () {
    return ({
      loading: false,
      submitting: false,
      restaurantData: {
        contacts: {}
      }
    })
  },

  methods: {
    getRestaurantInfo () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.collectionPath).get()
          .then(docSnapshot => { resolve(docSnapshot.data()) })
          .catch(e => { reject(e) })
      })
    },

    saveData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.collectionPath).set(Object.assign(
          { author: this.user.uid },
          this.$data.restaurantData
        ), { merge: true })
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },

    async submit () {
      this.$data.submitting = true
      try {
        await this.saveData()
        this.$store.commit('toast/setNotification', { color: 'success', content: 'Restaurant info updated.' })
      } catch (e) {
        console.error(e)
        this.$store.commit('toast/setError', 'Failed to update restaurant info.')
      }
      this.$data.submitting = false
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      Object.assign(this.$data.restaurantData, await this.getRestaurantInfo())
    } catch (e) {
      console.error(e)
      this.$store.commit('toast/setError', 'Failed to get restaurant info.')
    }
    this.$data.loading = false
  }
}
</script>
